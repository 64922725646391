<template>
  <q-card-section class="row q-px-none q-py-xs items-center" v-if="!source?.id">
    <div class="row items-center justify-center fit">
      <div class="text-subtitle1">
        {{ $t('Please fill the form below to connect your store.') }}
      </div>
    </div>

    <div class="row items-center justify-center fit">
      <div class="col-6 text-center q-my-md">
        <form-builder ref="formBuilder" :schema="schema"/>
      </div>
    </div>

    <div class="row items-center justify-center fit">
      <div class="col-6 text-right">
        <q-btn flat :label="$t('Create')" @click="handleAdd"/>
        <!--              <q-btn flat :label="$t('Discard')"/>-->
      </div>
    </div>
  </q-card-section>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'WooCommerceAppInstall',
  props: {
    app: {
      type: Object,
      default () {
        return {}
      }
    },
    source: {
      type: Object,
      default () {
        return {
          name: null,
          owner: null,
          settings: {
            general: {
              url: null,
            },
            auth: {
              key: null,
              secret: null,
            },
          }
        }
      }
    },
  },
  data () {
    return {
      defaultFormComponents: {},
      cleanModel: null,
      model: {
        name: null,
        owner: null,
        settings: {
          general: {
            url: null,
          },
          auth: {
            key: null,
            secret: null,
          },
        }
      },
      items: [],
      filters: [
        { type: 'in', field: 'state', values: ['active', 'inactive'] }
      ],
      authDialogEnabled: false,
      storeUrl: null,
    }
  },
  watch: {
    source: {
      handler (value) {
        this.model = value;
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      'app',
      'appOptions',
      'source',
      'isSupervisior',
      'isClient',
      'isEmployee',
      'isAdministrator',
    ]),
    schema () {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                if: this.isSupervisior || this.isAdministrator,
                type: 'multiselect',
                label: this.$t('Owner'),
                value: this.model.owner,
                required: true,
                hint: this.$t('Select the user for the connection.'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                noFilled: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      {
                        type: 'innerjoin',
                        field: 'roles',
                        parentAlias: 'u',
                        alias: 'r'
                      },
                      {
                        field: 'id',
                        alias: 'r',
                        type: 'eq',
                        value: 8
                      }
                    ]
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: (owner) => {
                  this.model.owner = owner
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.model.name,
                label: this.$t('Store name'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                noFilled: true,
                onChange: name => {
                  this.model.name = name
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.model.settings.general.url,
                label: this.$t('Store URL'),
                placeholder: 'https://woo.com',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                noFilled: true,
                onChange: name => {
                  this.model.settings.general.url = name
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.model.settings.auth.key,
                label: this.$t('Consumer key'),
                placeholder: 'ck_***',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                noFilled: true,
                onChange: name => {
                  this.model.settings.auth.key = name
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.model.settings.auth.secret,
                label: this.$t('Consumer secret'),
                placeholder: 'cs_***',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                noFilled: true,
                onChange: name => {
                  this.model.settings.auth.secret = name
                }
              },
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'setApp',
      'addWarningNotification',
      'addErrorNotification'
    ]),
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    handleChange (update) {
      this.model = update
      this.hasChange = true
    },
    handleAdd () {
      this.$emit('change-model', {
        model: this.model,
      })
    },
  }
}
</script>
