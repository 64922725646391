<template>
  <div v-if="app" class="q-ma-md">
    <q-card-section class="row q-px-none q-py-xs items-center">
      <!-- Right-side content: Connection ID and Name (only if model.id exists) -->
      <div class="col-auto" v-if="model?.id">
        <q-card class="q-pa-ms q-mb-lg text-right" v-if="model?.id">
          <q-card-section class="text-right">
            <div class="q-mb-xs">
              <q-icon name="perm_identity" class="q-mr-sm"/>
              <span>{{ $t('Connection ID') }}: <strong>{{ model.id }}</strong></span>
            </div>
            <div>
              <q-icon name="link" class="q-mr-sm"/>
              <span>{{ $t('Connection name') }}: <strong>{{ model.name }}</strong></span>
            </div>
          </q-card-section>
        </q-card>
      </div>

      <div class="col-auto q-ml-auto q-mr-lg" v-if="app.image">
        <!-- Logo with max height of 100px -->
        <q-img :src="app.image" style="width: 250px;max-height: 100px;"/>
      </div>

      <!-- If there is no logo, fall back to app.name -->
      <div v-else class="col-auto q-ml-auto q-mr-lg">
        <q-legend :label="app.name"/>
      </div>
    </q-card-section>

    <q-card-section class="row q-px-none q-py-xs items-center" v-if="!model?.id">
      <div v-if="authComponents[app.adapter]" class="full-width">
        <component
            :is="authComponents[app.adapter]"
            :app="app"
            @change-model="handleChangeAuthorization"
            no-connect-btn
        />
      </div>

      <div v-else class="full-width">
        <div class="row items-center justify-center fit">
          <div class="text-subtitle1">
            {{ $t('Please fill the form below to connect') }}
          </div>
        </div>

        <div class="row items-center justify-center fit">
          <div class="col-6 text-center q-my-md">
            <form-builder ref="formBuilder" :schema="schema"/>
          </div>
        </div>

        <div class="row items-center justify-center fit">
          <div class="col-6 text-right">
            <q-btn flat :label="$t('Create')" @click="handleAdd"/>
            <!--              <q-btn flat :label="$t('Discard')"/>-->
          </div>
        </div>
      </div>
    </q-card-section>

    <q-card-section class="row q-px-none q-py-xs items-center" v-if="model && model.id">
      <div v-if="defaultFormComponents[app.adapter]" class="q-px-sm">
        <component
            :is="defaultFormComponents[app.adapter]"
            :source="model"
            @change="handleChange"
            no-connect-btn
        />
      </div>

      <div
          v-if="!app.settings || app.settings.length === 0"
          class="row items-center text text-subtitle1"
          style="min-height: 100px;"
      >
        {{ $t('No settings available') }}
      </div>

      <div v-else class="col-12">
        <div v-for="group in app.settings" :key="group.name">
          <q-card class="q-mb-md full-width" v-if="group.attributes">
            <q-card-section>
              <div class="text-h6">{{ $t(group.label || 'No name') }}</div>
            </q-card-section>

            <q-separator/>

            <q-card-section>
              <integration-tab
                  :model="model"
                  :tab="group"
                  @change="handleChange"
              />
            </q-card-section>
          </q-card>
        </div>

        <q-card class="q-mb-md full-width" v-if="authComponents[app.adapter]">
          <q-card-section>
            <div class="text-h6">{{ $t('Authorization') }}</div>
          </q-card-section>

          <q-separator/>

          <q-card-section>
            <component
                :is="authComponents[app.adapter]"
                :app="app"
                :source="model"
                @change-model="handleChangeAuthorization"
                no-connect-btn
            />
          </q-card-section>
        </q-card>
      </div>
    </q-card-section>

    <sticky-bottom-header
        :is-loading="isSaveLoading"
        :is-active="hasChange"
        :save-label="$t(model?.id ? 'Save' : 'Install')"
        @back="handleDiscard"
        @save="save"
    />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import IntegrationForm from '../../components/integration-wizard/IntegrationForm.vue'
import ShopifyForm from './../../components/integration-wizard/ShopifyForm.vue'
import IntegrationTab from '../../components/integration-wizard/IntegrationTab.vue'
import Search from '../../components/search/Search'
import OAuth2AppInstall from '../../components/apps/install/OAuth2AppInstall'
import ShopifyAppInstall from '../../components/apps/install/ShopifyAppInstall'
import WooCommerceAppInstall from '../../components/apps/install/WooCommerceAppInstall'

// Utils
import _ from 'lodash'
import { buildQuery } from '../../utils/query-utils'
import { convertSourceIntegration } from '../../helpers/helpers'
import { convertEmbedded, difference } from '../../helpers/request-helpers'

export default {
  name: 'DefaultApp',
  components: {
    IntegrationForm,
    ShopifyForm,
    IntegrationTab,
    Search,
    OAuth2AppInstall,
    ShopifyAppInstall,
    WooCommerceAppInstall,
  },
  data () {
    return {
      defaultFormComponents: {},
      authComponents: {
        'amazon': OAuth2AppInstall,
        'ebay': OAuth2AppInstall,
        'etsy': OAuth2AppInstall,
        'quickbooks': OAuth2AppInstall,
        'shopify': ShopifyAppInstall,
        'woocommerce': WooCommerceAppInstall,
      },
      cleanModel: null,
      connectionName: null,
      connectionOwner: null,
      connectionSettings: {
        general: {
          url: '',
        },
        auth: {
          key: null,
          secret: null,
        },
      },
      prompt: false,
      model: null,
      isLoading: false,
      filter: '',
      filters: [
        { type: 'in', field: 'state', values: ['active', 'inactive'] }
      ],
      deliveryService: null,
      isSaveLoading: false,
      hasChange: false,
      deliveryId: null,
    }
  },
  computed: {
    ...mapGetters([
      'app',
      'appOptions',
      'appAdapters',
      'isSupervisior',
      'isClient',
      'isEmployee',
      'isAdministrator',
      'source',
    ]),
    isDS () {
      return this.app && this.app.adapter.includes('DeliveryServices')
    },
    schema () {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                if: this.isSupervisior || this.isAdministrator,
                type: 'multiselect',
                label: this.$t('Owner'),
                value: this.connectionOwner,
                required: true,
                hint: this.$t('Select the user for the connection.'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                noFilled: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      {
                        type: 'innerjoin',
                        field: 'roles',
                        parentAlias: 'u',
                        alias: 'r'
                      },
                      {
                        field: 'id',
                        alias: 'r',
                        type: 'eq',
                        value: 8
                      }
                    ]
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: (owner) => {
                  this.connectionOwner = owner
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.connectionName,
                label: this.$t('Connection name'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                noFilled: true,
                onChange: name => {
                  this.connectionName = name
                }
              },
            ]
          }
        ]
      }
    }
  },
  mounted () {
    if (!this.app) {
      this.loadApp(this.$route.params.id)
          .then(() => {
            this.onRequest({ pagination: { page: 1, per_page: 25 } })
          })
    }

    if (this.source) {
      this.setItem(this.source)
    } else if (this.$route.params.sourceId && this.$route.params.sourceId > 0) {
      this.loadSource(this.$route.params.sourceId).then(() => {
        this.setItem(this.source)
      })
    }
  },
  unmounted () {
    this.setApp(null)
    this.setItem(null)
    this.setSource(null)
  },
  methods: {
    ...mapActions([
      'loadApp',
      'loadSource',
    ]),
    ...mapMutations([
      'setApp',
      'setSource',
      'addWarningNotification'
    ]),
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    handleChange (update) {
      this.model = update
      this.hasChange = true
    },
    handleChangeAuthorization (result) {
      this.connectionName = result.model?.name
      this.connectionOwner = result.model?.owner
      this.connectionSettings = result.model?.settings

      this.handleAdd()
    },
    handleAdd () {
      // Check if all props in this.settings are non-empty
      let allPropsFilled = true // Assume all are filled initially

      for (const key in this.connectionSettings) {
        if (!this.connectionSettings[key]) {
          // If any property is empty (null, undefined, '', 0, false), set flag to false
          allPropsFilled = false
          break // Stop the loop if an empty property is found
        }
      }

      if (!allPropsFilled) {
        this.addWarningNotification('All parameters are required. Please fill in all fields before proceeding.')
        return
      }

      let item = {
        name: this.connectionName,
        handler: this.app.adapter,
        settings: this.connectionSettings,
        _embedded: {}
      }

      if (this.connectionOwner && (this.isSupervisior || this.isAdministrator)) {
        item.owner = this.connectionOwner.id
      }

      this.setItem(item)

      this.save()
    },
    onRowClick (item) {
      this.setItem(item)
    },
    handleNameChange (result, item) {
      return Promise.resolve(this.isDS)
          .then(isDS => {
            return isDS
                ? this.$service.deliveryServiceIntegration.save({ name: result }, item.id)
                : this.$service.iSource.save({ name: result }, item.id)
          })
          .then(item => {
            this.replaceItem(item)
          })
    },
    handleStateChange (item) {
      const invertedState = {
        active: 'inactive',
        inactive: 'active'
      }

      const state = invertedState[item.state]

      return Promise.resolve(this.isDS)
          .then(isDS => {
            return isDS
                ? this.$service.deliveryServiceIntegration.save({ state }, item.id)
                : this.$service.iSource.save({ state }, item.id)
          })
          .then(item => {
            this.replaceItem(item)
          })
    },
    getSIntegrations (query) {
      query.filter.push({ type: 'eq', field: 'handler', value: this.app.extId })

      return this.$service.iSource.getAll(query)
    },
    loadDeliveryService () {
      const query = {
        per_page: 5,
        page: 1,
        filter: [
          { type: 'eq', field: 'extId', value: this.app.extId }
        ]
      }

      return this.$service.deliveryService.getAll(query)
          .then(({ items }) => {
            this.deliveryService = items[0]
            return this.deliveryService
          })
    },
    loadDSItems (query) {
      return Promise.resolve(!!this.deliveryService)
          .then(hasDS => {
            return hasDS
                ? this.deliveryService
                : this.loadDeliveryService()
          })
          .then(deliveryService => {
            this.deliveryId = deliveryService.id
            query.filter.push({ type: 'eq', field: 'deliveryService', value: deliveryService.id })

            return this.$service.deliveryServiceIntegration.getAll(query)
          })
    },
    loadSItems (query) {
      query.filter.push({ type: 'eq', field: 'handler', value: this.app.extId })

      return this.$service.iSource.getAll(query)
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = [...this.filters]
      query['order-by'] = [
        { type: 'field', field: 'created', direction: 'desc' }
      ]
      query.sort = { id: 'desc' }

      this.isLoading = true
      return Promise.resolve()
          .then(() => {
            return this.isDS
                ? this.loadDSItems(query)
                : this.loadSItems(query)
          })
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.items = items

            return { items, totalPages, page, totalItems }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    setItem (item) {
      this.model = _.cloneDeep(item)
      this.cleanModel = item && item.id
          ? _.cloneDeep(item)
          : null
      this.hasChange = false
    },
    replaceItem (item) {
      let isFounded = false

      this.items = this.items.map(x => {
        if (x.id == item.id) {
          isFounded = true
          return item
        }

        return x
      })

      if (!isFounded) {
        this.items = [item, ...this.items]
      }

      return this.items
    },
    save () {
      this.isSaveLoading = true

      return Promise.resolve(this.isDS)
          .then(isDS => {
            return isDS
                ? this.saveDSIntegration()
                : this.saveSIntegration()
          })
          .then(data => {
            this.setItem(data)
            this.replaceItem(data)

            if (this.$route.params.sourceId !== this.model?.id) {
              this.$router.push(`/apps/ecommerce/entity/${this.app.adapter}/${this.$route.params.id}/${this.model.id}`)
            }
          })
          .finally(() => {
            this.isSaveLoading = false
          })
    },
    saveDSIntegration () {
      const ignoredFields = ['deliveryService', 'sender']

      let data = this.model.id
          ? convertEmbedded(difference(this.model, this.cleanModel), ignoredFields)
          : convertEmbedded(this.model)

      if (Object.keys(data).length === 0) {
        this.addWarningNotification('No changes!')
        return Promise.resolve(this.model)
      }

      if (data.settings) {
        data = convertSourceIntegration(data)
      }

      if (data.handler) {
        delete data.handler
      }
      if (data.owner) {
        delete data.owner
      }

      data.deliveryService = this.deliveryId
      data.mode = 'online'
      data.type = 'private'

      return this.$service.deliveryServiceIntegration.save(data, this.model.id)
    },
    saveSIntegration () {
      if (this.model.id) {
        let data = convertEmbedded(difference(this.model, this.cleanModel))

        if (data.settings) {
          data = convertSourceIntegration(data)
        }

        if (Object.keys(data).length === 0) {
          this.addWarningNotification('No changes!')
          return Promise.resolve(this.model)
        }

        return this.$service.iSource.save(data, this.model.id)
      }

      let data = { ...this.model }

      data = convertSourceIntegration(data)
      return this.$service.iSource.install(this.app.id, data)
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
  }
}
</script>
