<template>
  <div class="row items-center" style="min-height: 50vh;" v-if="!source?.id">
    <div class="col-12 text-center" v-if="!this.authDialogEnabled">
      <div class="row text justify-center q-mt-md">
        <q-btn
            color="light-blue-9"
            :label="$t('Connect new app')"
            size="1.5rem"
            class="q-mt-sm"
            @click="handleAdd"
        />
      </div>
    </div>

    <div v-else class="col-12 text-center">
      <div
          class="row items-center justify-center text-subtitle1"
          style="min-height: 300px; flex-direction: column;">
        <div>
          {{ $t('You will be redirected to the') }} {{ app.name }} {{ $t('website for sign in to your account!') }}
        </div>
        <div class="row items-center justify-center q-mt-md" style="gap: 30px;">
          <q-btn
              color="light-blue-9"
              :label="$t('Discard')"
              size="1rem"
              class="q-mt-sm"
              @click="removeItem"
          />
          <q-btn
              color="light-blue-9"
              label="Ok"
              size="1rem"
              class="q-mt-sm"
              @click="handleRedirected"
              :disable="storeUrl"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- The v-else part stays with no card -->
  <div v-else>
    <q-btn
        color="light-blue-9"
        label="Renew app authorization"
        size="1.5rem"
        class="q-mt-sm"
        @click="handleAdd"
    />
  </div>

</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'
import { HALApiService } from '@/apps/app/services'

export default {
  name: 'OAuth2AppInstall',
  props: {
    app: {
      type: Object,
      default () {
        return {}
      }
    },
    source: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      defaultFormComponents: {},
      cleanModel: null,
      model: null,
      items: [],
      filters: [
        { type: 'in', field: 'state', values: ['active', 'inactive'] }
      ],
      authDialogEnabled: false,
      storeUrl: null,
    }
  },
  computed: {
    ...mapGetters([
      'app',
      'appOptions',
      'source',
    ]),
  },
  methods: {
    ...mapMutations([
      'setApp',
      'addWarningNotification',
      'addErrorNotification'
    ]),
    getRedirectUrl () {
      return HALApiService.get(`/apps/${this.app.adapter}/settings?app=app&host=${window.location.host}` + (this.source?.id ? `&source=${this.source?.id}` : ``))
          .then(item => {
            window.location.href = item.button
            return item
          })
    },
    handleRedirected () {
      this.getRedirectUrl()
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    handleChange (update) {
      this.model = update
      this.hasChange = true
    },
    handleAdd () {
      this.authDialogEnabled = true
    },
  }
}
</script>
